// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-clean-o-jsx": () => import("./../../../src/pages/product/clean-o.jsx" /* webpackChunkName: "component---src-pages-product-clean-o-jsx" */),
  "component---src-pages-product-clean-t-jsx": () => import("./../../../src/pages/product/clean-t.jsx" /* webpackChunkName: "component---src-pages-product-clean-t-jsx" */),
  "component---src-pages-test-index-jsx": () => import("./../../../src/pages/test/index.jsx" /* webpackChunkName: "component---src-pages-test-index-jsx" */)
}

